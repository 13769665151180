<template>
    <div :class="['base-layout',orientation]">
        <div class="mr-1"> Tags: </div>
        <!-- <div style="max-height:90px; overflow-y: auto; overflow-x: hidden;"> -->
        <div style="overflow-x: hidden;">
            <div :class="['base-layout', orientation]">
                <div v-for="(t, index) in selectedTags" :index="index" :key="index" color="green" class="multiselect__tag">
                    {{t.name}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import api from '/src/utils/api';

    export default {
        data() {
            return {
                selectedTags: null,
            }
        },
        async mounted() {
            this.selectedTags = await api.getTags(this.listId);
            // console.log("this.selectedTags",this.selectedTags);
        },
        props: ['listId','orientation'],
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.base-layout {
    display: flex; 
}

.horizontal {
    flex-direction: column;
    justify-content: left; 
    align-items: center;
}

.vertical {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 4px;
}

.multiselect__tag {
    padding: 4px 10px 4px 10px;
}
</style>